import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import moment from "moment";
//import FileSaver from 'file-saver';

var httpClient = new HttpClient(ApiGateway.Emr);

export default {
    shortDaily: async () => {
        return httpClient.get(`/Report/Daily-short`, {});
    },
    hourDaily: async () => {
        return httpClient.get(`/Report/Daily-hour`, {});
    },
    payment: async (clinicID, startTime, endTime, statusID = 2) => {
        var query = { clinicID, startTime, endTime, statusID }
        return httpClient.get(`ExcelReport/Report/Data/PaymentSummary`, query);
    },
    paymentReport(clinicID, startTime, endTime, statusID = 2) {
        var query = { clinicID, startTime, endTime, statusID }
        var fileName = `[A2DS - PAS] Payment Report ${moment(startTime).format("DD-MM-YYYY")} - ${moment(endTime).format("DD-MM-YYYY")}`
        return this.dataUrlToFile(`ExcelReport/Report/Excel/PaymentSummary`, query, fileName);
    },
    summaryReport: function (start, end, filename) {
        return this.dataUrlToFile(`ExcelReport/Report/Excel/Combined`, { start, end }, filename);
    },
    fullReport: function (start, end, filename) {
        return this.dataUrlToFile(`ExcelReport/Report/Excel/Full`, { start, end }, filename);
    },
    dataUrlToFile: function (dataUrl, options, filename) {
        let type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        return httpClient.getFile(dataUrl, options, {}, type, filename);
    },
    getPaymentReportViaClinicDepartment: function (startTime, endTime, clinicID = null, departmentID = '', staffID = '') {
        return httpClient.get("/Report/PaymentReportViaClinicDepartment", { startTime, endTime, clinicID, departmentID, staffID}, {});
    },
    getOverviewToday: () => httpClient.get("/Report/OverviewToday")
}