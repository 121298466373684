<template>
  <td v-if="renderCell" :class="className">
    <template v-if="field == 'dateConfirmed'">
      <div class="d-flex flex-column">
        <b>{{ moment(getNestedValue(field, dataItem)).format('DD/MM/YYYY') }}</b>
      </div>
    </template>
    <template v-else-if="field == 'dateCreated'">
      <b>{{ moment(getNestedValue(field, dataItem)).format('DD/MM/YYYY') }}</b>
    </template>
    <template v-else-if="field == 'patientID'">
      <span class="primary--text">{{ getNestedValue(field, dataItem) }}</span>
    </template>
    <template v-else-if="field == 'patientName'">
      <span class="primary--text">{{ getNestedValue(field, dataItem) }}</span>
    </template>
    <template v-else-if="field == 'staffConfirmName'">
      <span class="font-weight-bold primary--text">{{ getNestedValue(field, dataItem) }}</span>
    </template>
    <template v-else-if="field == 'isIncluded'">
      <template v-if="getNestedValue(field, dataItem) == true">
        <div class="d-flex align-center w-100 justify-center">
          <span class="k-icon k-i-check green--text"></span>
        </div>
      </template>
    </template>

    <!-- <template v-else-if="field == 'typeID'">
      <div class="d-flex w-100 align-center justify-center">
        <v-chip label 
          small
          class="white--text"
          :color="auditTypeColor(getNestedValue(field, dataItem))"
        >
          {{ getNestedValue(field, dataItem) == AuditType.Ax ? 'Assessment' : '' }} 
          {{ getNestedValue(field, dataItem) == AuditType.Procedure ? 'Procedure' : '' }} 
          {{ getNestedValue(field, dataItem) == AuditType.Service ? 'Service' : '' }} 

        </v-chip>
      </div>
    </template> -->
    <template v-else-if="field == 'originalPrice' 
      || field == 'finalPrice' 
      || field == 'promotionPrice' 
      || field == 'differencePrice' 
      || field == 'afterPromotionPrice'
      || field == 'inServicePrice'">
      <b>{{ currencyFormat(getNestedValue(field, dataItem),'end', '') }}</b>
    </template>
    <template v-else>
      {{ getNestedValue(field, dataItem) }}
    </template>
  </td>
  <td v-else-if="renderArrow" key="'g' + columnIndex" :class="'k-group-cell'" ></td>
  <td v-else-if="columnIndex <= level" key='g-colspan'
      :colSpan="columnsCount - columnIndex">
      <p class="k-reset" :class="{'red--text': level == 1}">
          <a
            @click="onClick"
            tabIndex="-1"
            href="#"
            :class="expanded ? 'k-i-collapse k-icon' : 'k-i-expand k-icon'"
          />
          {{dataItem[field]}}
      </p>
  </td>
</template>

<script>
function nestedValue(fieldName, dataItem) {
  const path = fieldName.split('.');
  let data = dataItem;
  path.forEach((p) => {
      data = data ? data[p] : undefined;
  });

  return data;
}
import { AuditType } from '@/plugins/constant'
import moment from 'moment'
import { currencyFormat } from '@/plugins/helper'

export default {
  name: 'CustomCellDetailOrderComponent',
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  watch: {
  },
  computed: {
    renderArrow(){
      var returnValue = this.columnIndex === undefined ||this.level === undefined ||
        this.columnIndex < this.level ||this.columnsCount === undefined ||
        this.rowType !== 'groupHeader' || this.dataItem[this.field] === undefined;
      return returnValue && this.dataItem[this.field];
    },
    renderCell(){
      return this.field !== undefined && this.rowType !== 'groupHeader';
    },
  },
  data: function()
  {
    return {
      AuditType,
      expandedGroup: true
    }
  },
  methods: {
    moment,
    currencyFormat,
    getNestedValue: nestedValue,
    auditTypeColor(typeID) {
      switch(typeID) {
        case this.AuditType.Ax:
          return '#007bff';
        case this.AuditType.Procedure:
          return '#dc3545';
        case this.AuditType.Service:
          return '#ffc107';
        default:
          return null
      }
    },
    onClick() {
      // @ts-ignore
      console.log(this.dataItem, this.expanded)
      this.$emit('click');
    },
  }
}
</script>

<style>

</style>