<template>
  <v-sheet>
    <v-card elevation="0" outlined>
      <v-card-title class="d-flex align-center justify-space-between">
        <span class="font-weight-bold">Report department</span>
        <download-excel
          :data="reportDepartmentPrice"
          :fields="exportExcel.fields"
          type="xls"
          :name="exportExcel.name"
          :header="exportExcel.header"
          :worksheet="exportExcel.worksheet"
          :footer="exportExcel.footer"
          >
          <v-icon size="30" color="#007c00" style="cursor: pointer">mdi-microsoft-excel</v-icon>
        </download-excel>
      </v-card-title>
      <v-card-text>
        <template v-if="reportDepartmentPrice != null">
          <template v-if="reportDepartmentPrice.length">
            <div class="d-flex flex-row flex-wrap">
              <template v-for="(itemPartment, index) in reportDepartmentPrice" >
                <v-col cols="3" :key="index">
                  <v-sheet>
                    <v-card outlined elevation="0">
                      <v-card-title>
                        <span class="">Khoa: <b class="ml-2 primary--text">{{ itemPartment.departmentName }}</b></span>
                      </v-card-title>
                      <v-card-text>
                        <div class="d-flex flex-column" >
                          <div class="d-block mt-3">
                            <span class="float-left text-subtitle-1 font-weight-bold">Giá gốc: </span>
                            <span class="float-right red--text text-subtitle-1 font-weight-bold">{{ currencyFormat(itemPartment.originalPrice, 'end', ' VND') }}</span>
                          </div>
                          <div class="d-block mt-1">
                            <span class="float-left text-subtitle-1 font-weight-bold">Giá khuyến mãi: </span>
                            <span class="float-right red--text text-subtitle-1 font-weight-bold">{{ currencyFormat(itemPartment.promotionPrice, 'end', ' VND') }}</span>
                          </div>
                          <div class="d-block mt-1">
                            <span class="float-left text-subtitle-1 font-weight-bold">Giá thay đổi: </span>
                            <span class="float-right red--text text-subtitle-1 font-weight-bold">{{ currencyFormat(itemPartment.differencePrice, 'end', ' VND') }}</span>
                          </div>
                          <div class="d-block mt-1">
                            <span class="float-left text-subtitle-1 font-weight-bold">Thực nhận: </span>
                            <span class="float-right red--text text-subtitle-1 font-weight-bold">{{ currencyFormat(itemPartment.finalPrice, 'end', ' VND') }}</span>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-sheet>
                </v-col>
              
              </template>
            </div>
          </template>
          <template v-else>
            <div class="d-flex justify-center align-center pa-4">
              <span class="grey--text font-weight-bold">Data is empty!</span>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="d-flex flex-row">
            <v-col cols="3" class="pa-2" v-for="item in 3" :key="item" >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import JsonExcel from "vue-json-excel";
import moment from "moment";
import { currencyFormat } from '@/plugins/helper'
export default {
  name: 'ReportDepartmentComponent',
  components: {
    'download-excel': JsonExcel
  },
  props: {
    reportDepartmentPrice: {
      type: Array,
      default: function() { return null }
    },
    searching: Object
  },
  watch: {
    searching() {
      this.exportExcel.customHeader(this.searching)
    }
  },
  data: function() { 
    return {
      exportExcel: {
        fields: {
          'Khoa': 'departmentName',
          'Tổng giá gốc': 'originalPrice',
          'Tống giá KM': 'promotionPrice',
          'Tổng giá thay đổi': 'differencePrice',
          'Thành tiền': 'finalPrice'
        },
        name: 'ReportCash.xls',
        header: ['<h5>Report Money</h5>'],
        footer: [],
        customHeader: function(searching) {
          this.header = ['<h5>REPORT MONEY</h5>'];
          let {startTime, endTime, clinicName, departmentName}  = searching;
          let timing = `<b style="color: red">${moment(startTime).format('DD/MM/YYYY')}</b>  -  <b style="color: red">${moment(endTime).format('DD/MM/YYYY')}</b>`;
          this.header.push(timing);
          let clinic = clinicName == '' ? 'Clinic: <b style="color:red">ALL</b>' : `Clinic: <b style="color:red">${clinicName}</b>`;
          this.header.push(clinic);
          let department = departmentName == '' ? 'Department: <b style="color:red">ALL</b>': `Department: <b style="color:red">${departmentName}</b>`
          this.header.push(department)
        }
      } 
    }
  },
  methods: {
    currencyFormat
  }
}
</script>

<style>

</style>