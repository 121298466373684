<template>
  <v-sheet>
    <v-form
      ref="searchingForm"
      v-model="validFormSearching"
     >
      <v-row>
        <!-- date picker range -->
        <v-col xl="3" sx="12" md="3" lg="3">
          <v-menu
            ref="menu"
            v-model="menuPickerRangeDate"
            :close-on-content-click="false"
            :return-value.sync="dateRanges"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="From - To"
                prepend-icon="mdi-calendar"
                readonly  
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRanges"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menuPickerRangeDate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="saveRangeDate"
                >
                  OK
                </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Select clinic -->
        <v-col xl="2" sx="12" md="3" lg="2">
          <v-select 
            v-model="listClinic.selection"
            item-text="clinicName"
            :error-messages="listClinic.error_messages"
            :error="listClinic.error"
            :items="listClinic.items"
            :loading="listClinic.loading"
            persistent-hint
            return-object
            label="Select clinic:"
            :hint="showClinicDescription"
            :rules="[v => !!v || 'Select clinic is required']"
            required
          ></v-select>
        </v-col>

        <!-- Select department -->
        <v-col xl="3" sx="12" md="3" lg="2" v-if="listDepartment.show">
          <v-select 
            v-model="listDepartment.selection"
            item-text="name"
            :error-messages="listDepartment.error_messages"
            :error="listDepartment.error"
            :items="listDepartment.items"
            :loading="listDepartment.loading"
            persistent-hint
            return-object
            label="Select department:"
            :hint="showDepartmentDescription"
          ></v-select>
        </v-col>

        <!-- Button searching -->
        <v-col xl="3" sx="12" md="3" lg="2" class="d-flex align-center">
          <v-btn color="primary" small @click="submitSearching" :disabled="!validFormSearching">
            Searching
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
  
</template>

<script>
import BrandService from '@/services/brand'
import DepartmentService from '@/services/department'
import moment from 'moment'

export default {
  name: 'DetailSearchingDetailPaymentComponent',

  watch: {
    dateRanges() {
      this.searching = {
        ...this.searching,
        startTime: this.dateRanges[0] != 'undefined' ? this.dateRanges[0] : '',
        endTime: this.dateRanges[1] != 'undefined' ? this.dateRanges[1] : ''
      }
    },
    'listClinic.selection': {
      deep: true,
      handler() {
        this.searching.clinicID = this.listClinic.selection.clinicID;
        this.searching.clinicName = this.listClinic.selection.clinicName;
      }
    },
    'searching.clinicID': {
      deep: true,
      handler() {
        if (this.searching.clinicID == null) {
          // hide selection of department
          this.listDepartment.show = false;
          this.searching.departmentID = ''
        } else {
          this.listDepartment.show = true;
          // search department
          this.asyncsearchingDepartment()
        }
      }
    },
    'listDepartment.selection': {
      deep: true,
      handler() {
        this.searching.departmentID = this.listDepartment.selection.id; // id of department record
        this.searching.departmentName = this.listDepartment.selection.name;
      }
    },
  },
  computed: {
    showClinicDescription() {
      return this.listClinic.selection?.description 
    },
    showDepartmentDescription() {
      return this.listDepartment.selection?.description
    }
  },

  data: function() { 
    return {
      validFormSearching: true,
      menuPickerRangeDate: false,
      dateRangeText: '',
      dateRanges: [],

      searching: {
        startTime: '',
        endTime: '',
        clinicID: '',
        departmentID: '',
        clinicName: '',
        departmentName: '',
      },

      listClinic: {
        items: [],
        loading: false,
        error: false,
        error_messages: '',
        selection: null
      },

      listDepartment: {
        items: [],
        show: false,
        loading: false,
        error: false,
        error_messages: '',
        selection: {}
      }
    }
  },
  created() {
    this.searchClinicByBrand()
    this.initTiming()
  },
  methods: {
    saveRangeDate() {

      let fromDate = this.dateRanges[0];
      let toDate = this.dateRanges[1];
      
      if (moment(fromDate).isAfter(toDate)) {
        this.searching = {
        ...this.searching,
          startTime: toDate,
          endTime: fromDate
        }
        this.dateRanges = [toDate, fromDate]
      }

      this.dateRangeText = this.dateRanges.join(" ~ ");
      this.$refs.menu.save(this.dateRanges)
    },

    async searchClinicByBrand() {
      this.listClinic.loading = true
      var listClinic = await BrandService.searchBrandClinics(1, 1000); // I think the back end should mark limit = all when limit from header equal -1
      if (listClinic.error) {
        this.listClinic = {
          loading: false,
          items: [],
          error: true,
          error_mesages: 'Can not load a list clinic from brand'
        }
        return;
      }
      this.listClinic.items = listClinic.items;
      // this.listClinic.items.unshift({
      //   clinicID: null,
      //   clinicName: 'All',
      //   description: '',
      //   id: ''
      // }); 
      this.listClinic.loading = false
    },
    initTiming() {
      // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      // const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
      // this.dateRanges = [startOfMonth, endOfMonth]
      const startOfMonth = moment().format('YYYY-MM-DD');
      const endOfMonth = moment().format('YYYY-MM-DD');
      this.dateRanges = [startOfMonth, endOfMonth]
      this.searching = {
        ...this.searching,
        startTime: startOfMonth,
        endTime: endOfMonth
      }
      this.dateRangeText = this.dateRanges.join(" ~ ");
    },
    returnSearching() {
      return this.searching;
    },
    submitSearching() {
      if (this.$refs.searchingForm.validate()) {
        this.$emit('searching-payment', this.searching);
      }
    },
    async asyncsearchingDepartment() {
    
      var searchDepartment = await DepartmentService.searchDepartmentByClinic('', this.searching.clinicID, 1, -1)
      if (searchDepartment.error) {
        this.listDepartment = {
          ...this.listDepartment,
          error: true, 
          error_messages: 'Can not load list department!',
          loading: false
        }
        return;
      }
      this.listDepartment = {
        ...this.listDepartment,
        items: searchDepartment.items,
        error: false,
        error_messages: '',
        loading: false
      }
    }
  }

}
</script>

<style>

</style>