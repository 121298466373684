<template>
  <v-card elevation="0" outlined>
    <v-card-title class="d-flex align-center justify-space-between">
      <span class="font-weight-bold">Report cashier</span>
      <download-excel
        :data="listPaymentWithReceptionist"
        :fields="exportExcel.fields"
        type="xls"
        :name="exportExcel.name"
        :header="exportExcel.header"
        :worksheet="exportExcel.worksheet"
        :footer="exportExcel.footer"
        >
        <v-icon size="30" color="#007c00" style="cursor: pointer">mdi-microsoft-excel</v-icon>
      </download-excel>
    </v-card-title>
    <v-card-text>
      <template v-if="listPaymentWithReceptionist != null">
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th>Thu ngân</th>
                <th>Gía gốc</th>
                <th>Giá KM</th>
                <th>Thay đổi</th>
                <th>Thực nhận</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="listPaymentWithReceptionist.length">
                <tr v-for="(payment, index) in listPaymentWithReceptionist" :key="index">
                  <th style="font-size: 16px" class="font-weight-bold">{{ payment.cashier }}</th>
                  <th style="font-size: 16px">{{ currencyFormat(payment.originalPrice, 'end', '') }}</th>
                  <th style="font-size: 16px">{{ currencyFormat(payment.promotionPrice, 'end', '') }}</th>
                  <th style="font-size: 16px">{{ currencyFormat(payment.differencePrice, 'end', '') }}</th>
                  <th class="text-subtitle-1 font-weight-bold red--text">{{ currencyFormat(payment.finalPrice, 'end',' ') }}</th>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    <span class="grey--text font-weight-bold">Data is empty!</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <template v-else>
        <v-skeleton-loader
          type="table-thead,  table-row"
        ></v-skeleton-loader>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import JsonExcel from "vue-json-excel";
import moment from 'moment'
import { currencyFormat } from '@/plugins/helper'
export default {
  name: 'ReportCashierComponent',
  components: {
    'download-excel': JsonExcel
  },
  props: {
    searching: null,
    listPaymentWithReceptionist: {
      type: Array,
      default: function () { return null }
    }
  },

  watch: {
    searching() {
      this.exportExcel.customHeader(this.searching)
    }
  },
  data: function() {
    return {
      exportExcel: {
        fields: {
          'Thu ngân': 'cashier',
          'Giá gốc': 'originalPrice',
          'Giá KM': 'promotionPrice',
          'Giá điều chỉnh': 'differencePrice',
          'Thực nhận': 'finalPrice'
        },
        name: 'ReportCashier.xls',
        header: ['<h5>Report Cashier</h5>'],
        footer: [],
        customHeader: function(searching) {
          this.header = ['<h5>REPORT CASHIER</h5>']
          let {startTime, endTime, clinicName, departmentName}  = searching;
          let timing = `${moment(startTime).format('DD/MM/YYYY')}  -  ${moment(endTime).format('DD/MM/YYYY')}`;
          this.header.push(timing);
          let clinic = clinicName == '' ? 'Clinic: <b style="color:red">ALL</b>' : `Clinic: <b style="color:red">${clinicName}</b>`;
          this.header.push(clinic);
          let department = departmentName == '' ? 'Department: <b style="color:red">ALL</b>': `Department: <b style="color:red">${departmentName}</b>`
          this.header.push(department)
        },
      }
    }
  },

  methods: {
    currencyFormat
  }
}
</script>

<style>

</style>