<template>
  <div>
    <grid-column-menu-sort 
      v-if="false"
      :column="column"
      :sortable="sortable"
      :sort="sort"
      @closemenu="closeMenu"
      @sortchange="sortChange"
    />
    <grid-column-menu-filter
      :column="column"
      :filterable="filterable"
      :filter="filter"
      @filterchange="filterMenuFilter"
    />

    <!-- Control the display column -->
    <grid-column-menu-item-group>
      <grid-column-menu-item
        :title="'Columns'" 
        :icon-class="'k-i-columns'"
        @menuitemclick="onMenuItemClick"
      />
      <grid-column-menu-item-content :show="columnsExpanded">
        <div class="k-column-list-wrapper">
          <!-- form -->
          <form @submit="onSubmit" @reset="onReset">
            <div class='k-column-list'>
              <div :key="index" class='k-column-list-item' v-for="(column, index) in currentColumns">
                <span>
                  <input 
                    :id="`column-visibility-show-${index}`"
                    class="k-checkbox"
                    type="checkbox"
                    :readonly="true"
                    :disabled="!column.hidden && oneVisibleColumn"
                    :checked="!column.hidden"
                    @click="onToggleColumn(index)"

                  />
                  <label
                    :for="`column-visiblity-show-${index}`"
                    :class="'k-checkbox-label'"
                    :style="{ userSelect: 'none' }"
                    >
                      {{column.title}}
                  </label>
                </span>
              </div>
            </div>
            <div class='k-columnmenu-actions'>
              <button type='reset' class='k-button'>Reset</button>
              <button class='k-button k-primary'>Save</button>
            </div>
          </form>
        </div>
      </grid-column-menu-item-content>
    </grid-column-menu-item-group>

    <!-- Lock or Un-lock state of column -->
    <grid-column-menu-item
      :title="lockedState ? 'Lock' : 'Un-lock'"
      :icon-class="lockedState ? 'k-i-lock' : 'k-i-unlock'"
      @menuitemclick="changeLockState"
    />

    <!-- filter by check-box -->
    <grid-column-checkbox-filter
      :column="column"
      :filter="filter"
      :filterable="filterable"
      :unique-data="uniqueData"
      :search-box="true"
      :data-items="dataResult"
      :expanded="true"
      @closeMenu="closeMenu"
      @filterchange="filterCheckbox"
    />
  </div>
</template>

<script>
import { 
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter, 
  GridColumnMenuSort, 
  GridColumnMenuItemGroup, 
  GridColumnMenuItemContent,
  GridColumnMenuItem  } from '@progress/kendo-vue-grid';

export default {
  name: 'GridHeadColumnMenuComponent',

  components: {
    'grid-column-checkbox-filter': GridColumnMenuCheckboxFilter,
    'grid-column-menu-filter': GridColumnMenuFilter,
    'grid-column-menu-sort': GridColumnMenuSort,
    'grid-column-menu-item-group': GridColumnMenuItemGroup,
    'grid-column-menu-item-content': GridColumnMenuItemContent,
    'grid-column-menu-item': GridColumnMenuItem  
  },
  props: {  
    column: Object,
    sortable: [Boolean, Object],
    sort: Array,
    filter: Object,
    filterable: Boolean,
    columns: Array,
    dataResult: Array
  },
  computed: {
    oneVisibleColumn(){
      return this.currentColumns.filter(c => !c.hidden).length === 1;
    },
    lockedState() {
      let findField = this.currentColumns.find(c => c.field == this.column.field);
      if (findField) {
        if (typeof findField.locked != 'undefined') {
          return findField.locked;
        }
        return false;
      } 
      return false;
    }
  },
  data() {
    return {
      currentColumns: [],
      columnsExpanded: false,
      filterExpanded: false,
      filterExpandedData: false,
      uniqueData: true // use this to prevent the duplication data
    }
  },
  created() {
    this.currentColumns = JSON.parse(JSON.stringify(this.columns));
  },  
  watch: {
    columns() {
      this.currentColumns = JSON.parse(JSON.stringify(this.columns));
    }
  },
  methods: {
    onMenuItemClick () {
      const value = !this.columnsExpanded;
      this.columnsExpanded = value;
      this.filterExpanded = value ? false : this.filterExpanded;
    },
    onMenuFilterDataItem() {
      const value = !this.filterExpandedData;
      this.filterExpandedData = value;
    },
    closeMenu () {
      this.$emit('closemenu');
    },
    sortChange (newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e);
    },
    onReset (event) {
      event.preventDefault();
      const allColumns = this.$props.columns.map(col => {
          return {
              ...col,
              hidden: false
          };
      });

      this.currentColumns = allColumns; 
      this.onSubmit();
    },
    onSubmit (event) {
      if (event) {
          event.preventDefault();
      }
      this.$emit('columnssubmit', this.currentColumns);
      this.$emit('closemenu');
    },
    onToggleColumn (indexBeFound) {
      this.currentColumns = this.currentColumns.map((column, idx) => {
        return idx === indexBeFound ? { ...column, hidden: !column.hidden } : column;
      });
    },
    filterCheckbox(filterPartten) {
      this.$emit('filter-checkbox', filterPartten)
    },
    filterMenuFilter(filterPartten) {
      this.$emit('filter-menu-filter', filterPartten)
    },
    changeLockState() {
      this.$emit('change-state-lock-column', this.column)
    }
  }
}
</script>