<template>
  <v-sheet class="pa-3">
    <searching-detail-payment
      @searching-payment="searchingPaymentReport"
      ref="searchingOrderPaymentComponent"
    />
    <v-sheet class="mt-5">
      <v-skeleton-loader
        v-if="listOrderPayment.loading"
        :loading="listOrderPayment.loading"
        type="table"
      ></v-skeleton-loader>
      <!-- pager container -->
      <pager-container
        v-else
        :total="listOrderPayment.total"
        :config="configPager"
        v-model="configPager"
        >
        <div class="d-flex justify-center">
            <span class="text-h5 font-weight-bold">REPORT PAYMENT <span v-html="renderHeader"></span></span>
        </div>
        <!-- Control list data -->
        <div class="d-flex w-100 mt-1 mb-2 justify-space-between pl-2 pr-2 align-center">
          <v-switch
            v-model="showDetailItems"
            :label="!showDetailItems ? 'Show a individual package' : 'Show a detail items'"
          ></v-switch>
          <div class="d-flex align-center">
            <download-excel
              :data="listOrderPayment.items"
              :fields="exportExcel.detailOrder.fields"
              type="xls"
              :name="exportExcel.detailOrder.name"
              :header="exportExcel.detailOrder.header"
              :worksheet="exportExcel.detailOrder.worksheet"
              :footer="exportExcel.detailOrder.footer"
              >
              <v-icon size="30" color="#007c00" style="cursor: pointer">mdi-microsoft-excel</v-icon>
            </download-excel>

            <v-icon size="30" color="red" class="ml-2" @click="savePDF">mdi-pdf-box</v-icon>
          </div>
         
        </div>
        <!-- export pdf -->
        <pdf-export ref="PdfExport"  
          :scale="0.4"
          :paper-size="'A4'"
          margin="0.2cm"
          >
          <!-- grid - data -->
          <grid-data
            ref="gridData"
            :columns="columns"
            :config="configGrid"
            :dataItems="listOrderPayment.items"
          ></grid-data>
        </pdf-export>
       
        <!-- Error -->
        <v-alert
          v-if="listOrderPayment.error"
          type="error"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ listOrderPayment.error_messages }}
            </v-col>
            <v-col class="shrink">
              <v-btn icon small @click="listOrderPayment.error = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </pager-container>

      <div class="d-block mt-4">
        <v-row justify="space-between"> 
          <!-- Report payment money -->
          <v-col xl="5" sx="12" md="6">
            <report-money-clinic
              :searching="searching"
              :reportPaymentPrice="reportPaymentPrice"
            />
          </v-col>

          <!-- Report receptionist -->
          <v-col xl="5" sx="12" md="6">
            <report-cashier
              :searching="searching"
              :listPaymentWithReceptionist="listPaymentWithReceptionist"
            />
          </v-col>
        </v-row>
        <v-row>
          <!-- Report department if having -->
          <v-col cols="12">
            <report-department
              :searching="searching"
              :reportDepartmentPrice="reportDepartmentPrice"
            />
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import SearchingDetailPayment from './components/SearchingDetailPayment'
import PagerContainerComponent from '@/components/kendo/PagerContainerComponent'
import GridDataComponent from '@/components/kendo/GridDataComponent'
import CustomOrderCell from './components/CustomOrderCell'
import ReportDepartment from './components/ReportDeparment'
import ReportCashier from './components/ReportCashier'
import ReportMoneyClinic from './components/ReportMoney'
import ReportBusiness from '@/services/report'
import moment from 'moment'
import { currencyFormat } from '@/plugins/helper'
import _ from 'lodash'
import { AuditType } from '@/plugins/constant'
import JsonExcel from "vue-json-excel";
import { PDFExport,  } from "@progress/kendo-vue-pdf";
export default {
  name: "DetailOrderPaymentReportComponent",
  components: {
    'searching-detail-payment': SearchingDetailPayment,
    'pager-container': PagerContainerComponent,
    'grid-data': GridDataComponent,
    "download-excel": JsonExcel,
    'report-department': ReportDepartment,
    'report-cashier': ReportCashier,
    'report-money-clinic': ReportMoneyClinic,
    'pdf-export': PDFExport
  },
  watch: {
    configPager() {
      this.configGrid = {
        ...this.configGrid,
        take: this.configPager.take,
        skip: this.configPager.skip
      }
    },
    showDetailItems() {
      if (!this.showDetailItems) {
        this.listOrderPayment.items = JSON.parse(JSON.stringify(
          this.listOrderPayment.source.filter(i => 
            i.typeID == AuditType.Service || (i.typeID != AuditType.Service && i.serviceID == null)).map(item => 
              {
                item.packageService = item.typeID == AuditType.Service ? item.serviceName : item.axPxName
                return item;
              }
            )
          ));
      } else {
        this.listOrderPayment.items = JSON.parse(JSON.stringify(
          this.listOrderPayment.source.filter(i => i.typeID != AuditType.Service).map(i => {
            if (i.serviceID != null) {
              i.originalPrice = i.inServicePrice;
            }
            return i
          })
          )
        )
      }
      // get all payment price
      this.getAllPaymentPrice()
      // calculate again when change the ShowDetailItems State
      this.getPriceAndCashier(this.listOrderPayment.items);
      // re-render the columns 
      this.reRenderColumns();
      this.reportDepartment();
      console.log(this.listOrderPayment.items)
    }
  },
  computed: {
    renderHeader() {
      return `${this.searching.startTime != '' && this.searching.endTime  != '' ? `[<b class='primary--text'>${this.searching.startTime}</b> to <b class='primary--text'>${this.searching.endTime}</b>]`: ''}`
    }
  },
  data: function() { 
    return {
      showDetailItems: false,

      searching: {
        startTime: '',
        endTime: '',
        clinicName: '',
        departmentName: '',
      }, // use for title of report excel

      listOrderPayment: {
        loading: false,
        error: false,
        error_messages: '',
        items:[],
        source: [],
        total: 0
      },
      configPager: {
        skip: 0,
        take: 10
      },
      columns: [
        { field: 'order', title: 'STT', width: '80px'},
        { field: 'dateCreated', title: 'Ngày tạo', filter: 'date', width: '200px'},
        { field: 'dateConfirmed', title: 'Ngày thanh toán',filter: 'date', width: '200px'},
        { field: 'clinicName', title: 'Clinic', width: '200px', columnMenu: 'headColumnMenu' }, 
        { field: 'patientID', title: 'Mã BN', width: '150px' },
        { field: 'patientName', title: 'Tên BN', width: '150px' },
        { field: 'axPxName', title: 'Item', width: '200px', columnMenu: 'headColumnMenu'},
        { field: 'serviceName', title: 'Dịch vụ', width: '250px' },
        { field: 'isIncluded', title: 'Thuộc service', width: '100px', filterable: false, groupable: false },
        { field: 'departmentName', title: 'Khoa', width: '150px', columnMenu: 'headColumnMenu'}, 
        { field: 'paymentMethodDescription', title: 'HT thanh toán', width: '150px', columnMenu: 'headColumnMenu'},
        { field: 'promotionName', title: 'CT Khuyến mãi', width: '150px' },
        { field: 'originalPrice', title: 'Giá gốc', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalOriginalPriceCell },
        { field: 'inServicePrice', title: 'Giá trong Service', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalPriceInService },
        { field: 'promotionPrice', title: 'Giá KM', width: '200px', footerCell: this.totalPromotionPriceCell },
        { field: 'afterPromotionPrice', title: 'Giá sau KM', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalAfterPromotionPrice },
        { field: 'differencePrice', title: 'Giá điều chỉnh', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalDifferencePriceCell },
        { field: 'finalPrice', title: 'Thực nhận', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalFinalPriceCell },
        { field: 'cashier', title: 'Thu ngân', width: '150px' },
        { field: 'note', title: 'Ghi chú', width: '250px' }
      ],
      configGrid: {
        sortable: true,
        filterable: true,
        groupable: true,
        reorderable: true,
        resizable: true,
        columnMenu: true,

        skip: 0,
        take: 20,
        sort: [],
        group: [],
        filter: null,

        custom: true,
        cellComponent: CustomOrderCell,
      },

      listPaymentWithReceptionist: null,

      reportPaymentPrice: null,

      reportDepartmentPrice: null,

      exportExcel: {
        detailOrder: {
          fields: {},
          customFields: function(showDetailItems) {
            if (!showDetailItems) { // show only package
              this.fields = {
                'STT': 'order',
                'Ngày tạo': 'dateCreated',
                'Ngày thanh toán': 'dateConfirmed',
                'Mã BN': 'patientID',
                'Tên BN': 'patientName',
                'Dịch vụ': 'packageService',
                'Khoa': 'departmentName',
                'HT Thanh toan': 'paymentMethodDescription',
                'CT khuyến mãi': 'promotionName',
                'Giá gốc': 'originalPrice',
                'Giá KM': 'promotionPrice',
                'Giá sau KM': 'afterPromotionPrice',
                'Điều chỉnh': 'differencePrice',
                'Thực nhận': 'finalPrice',
                'Thu ngân': 'receiptionist',
                'Ghi chú': 'note'
              }
            } else {
              this.fields = {
                'STT': 'order',
                'Ngày tạo': 'dateCreated',
                'Ngày thanh toán': 'dateConfirmed',
                'Mã BN': 'patientID',
                'Tên BN': 'patientName',
                'Item': 'axPxName',
                'Dịch vụ': 'serviceName',
                'Khoa': 'departmentName',
                'HT Thanh toan': 'paymentMethodDescription',
                'CT khuyến mãi': 'promotionName',
                'Giá gốc': 'originalPrice',
                'Giá KM': 'promotionPrice',
                'Giá sau KM': 'afterPromotionPrice',
                'Điều chỉnh': 'differencePrice',
                'Thực nhận': 'finalPrice',
                'Thu ngân': 'receiptionist',
                'Ghi chú': 'note'
              }
            }
          },
          name: 'ReportByDate.xls',
          header: ['<h5>Report detail payment</h5>'],
          customHeader: function(searching, showDetailItems) { 
            if (showDetailItems) {
              this.header = ['<h5>REPORT DETAIL ORDER PAYMENT</h5>']
            } else {
              this.header = ['<h5>REPORT PACKAGE SERVICE PAYMENT</h5>']
            }

            let {startTime, endTime, clinicName, departmentName}  = searching;
            let timing = `${moment(startTime).format('DD/MM/YYYY')}  -  ${moment(endTime).format('DD/MM/YYYY')}`;
            this.header.push(timing);
            let clinic = clinicName == '' ? 'Clinic: <b style="color:red">ALL</b>' : `Clinic: <b style="color:red">${clinicName}</b>`;
            this.header.push(clinic);
            let department = departmentName == '' ? 'Department: <b style="color:red">ALL</b>': `Department: <b style="color:red">${departmentName}</b>`
            this.header.push(department)
          }
        },
      }
    }
  },
  mounted() {
    // use for testing
    // let searching = this.$refs.searchingOrderPaymentComponent.returnSearching();
    // searching.clinicID = 5;
    // this.searchingPaymentReport(searching)
  },
  methods: {
    currencyFormat,
    async searchingPaymentReport(searching) {
      let { startTime, endTime, clinicID, departmentID, clinicName, departmentName } = searching
      this.searching =  {
        startTime,
        endTime,
        clinicName: clinicName ?? '',
        departmentName: departmentName ?? ''
      },
      this.listOrderPayment.loading = true;
      let resultSearching = await ReportBusiness.getPaymentReportViaClinicDepartment(startTime, endTime, clinicID, departmentID);
      if (resultSearching.error) {
        this.listOrderPayment = {
          ...this.listOrderPayment,
          loading: false,
          error: true,
          error_messages: 'Something went wrong when retrieving data!',
        }
      }

      console.log(resultSearching.items)

      let result = resultSearching.items.map((item, index) => {
        // order
        item.order = index + 1;
        // the order is belongs to Service or invidually
        item.isIncluded = item.serviceID == null ? false : true;
        // format date created 
        // item.dateCreated = moment(item.dateCreated).format('DD-MM-YYYY'),
        // format date confirmed
        // item.dateConfirmed = moment(item.dateConfirmed).format('DD-MM-YYYY');
        // math round for all price
        item.afterPromotionPrice = Math.round(item.afterPromotionPrice);
        item.finalPrice = Math.round(item.finalPrice == null ? 0 : item.finalPrice);
        item.promotionPrice = Math.round(item.promotionPrice);
        item.differencePrice = Math.round(item.differencePrice);
        item.inServicePrice = Math.round(item.inServicePrice);

        return item;
      });
      if (!this.showDetailItems) { // show only individual package
        result = result.filter(i => i.typeID == AuditType.Service || (i.typeID != AuditType.Service && i.serviceID == null)).map(item => 
          {
            item.packageService = item.typeID == AuditType.Service ? item.serviceName : item.axPxName
            return item;
          }
        );  
      } else {
        // show detail in Service
        result = result.filter(i => i.typeID != AuditType.Service).map(i => {
          if (i.serviceID != null) {
            i.originalPrice = i.inServicePrice;
          }
          return i
        });
      }

      // render again columns via the condition showDetailItems
      this.reRenderColumns()

      // .filter(i => i.typeID != AuditType.Service)
      this.listOrderPayment = {
        items: result,
        source: JSON.parse(JSON.stringify(resultSearching.items)),
        loading: false,
        error: false,
        total: result.length
      }
      // calculate all price to display
      this.getAllPaymentPrice()
      // get cashier and price
      this.getPriceAndCashier(this.listOrderPayment.items)
      // get report department price
      this.reportDepartment()
      
    },
    formatReturnTotalCurrency(h, value) {
      return h('div', {
          'class': ['d-flex w-100 justify-space-between']
        }, [
        h('span', {}, ['Total: ']),
        h('b', {
          'style': {'color': 'red', 'textAlign': 'right'}
        }, [value])
      ]);
    },
    getCurrency(nameProps, format = true) {
      if (this.listOrderPayment.items.length)
      {
        let total = this.listOrderPayment.items.reduce((previous, item) => {
          if (_.isNumber(item[nameProps])) {
            return previous + item[nameProps]
          } else {
            return previous;
          }
        }, 0)

        return format ? this.currencyFormat(total,'end',' VND') : total
      } else {
        return 0;
      }
    },

    totalOriginalPriceCell(h) {
      let totalOriginal = this.listOrderPayment.items.length > 0 ? this.getCurrency('originalPrice'): 0
      return this.formatReturnTotalCurrency(h, totalOriginal);
    },
    totalPromotionPriceCell(h) {
      let totalPromotion = this.listOrderPayment.items.length > 0 ? this.getCurrency('promotionPrice'): 0
      return this.formatReturnTotalCurrency(h, totalPromotion);
    },
    totalDifferencePriceCell(h) {
      let totalDifference = this.listOrderPayment.items.length > 0 ? this.getCurrency('differencePrice'): 0
      return this.formatReturnTotalCurrency(h, totalDifference);
    },
    totalFinalPriceCell(h) {
      let totalFinal = this.listOrderPayment.items.length > 0 ? this.getCurrency('finalPrice'): 0
      return this.formatReturnTotalCurrency(h, totalFinal);
    },
    totalAfterPromotionPrice(h) {
      let totalAfterPromotionPrice = this.listOrderPayment.items.length > 0 ? this.getCurrency('afterPromotionPrice'): 0
      return this.formatReturnTotalCurrency(h, totalAfterPromotionPrice);
    },

    totalPriceInService(h) {
      let totalPriceInService = this.listOrderPayment.items.length > 0 ? this.getCurrency('inServicePrice'): 0
      return this.formatReturnTotalCurrency(h, totalPriceInService);
    },

    getAllPaymentPrice() {
     
      let data =  [ 
        {
          text: 'Tổng giá gốc', cash: this.getCurrency('originalPrice', false),
        },
        {
          text: 'Tổng giá KM', cash: this.getCurrency('promotionPrice', false)
        },
        {
          text: 'Tổng giá thay đỏi', cash: this.getCurrency('differencePrice', false)
        },
        {
          text: 'Tổng thực nhận', cash: this.getCurrency('finalPrice', false)
        }
      ]
      this.reportPaymentPrice = data
    },

    getPriceAndCashier(list) {
      // refresh listPaymentWithReceptionist array
      this.listPaymentWithReceptionist = []
      let listQuery = JSON.parse(JSON.stringify(list));
      listQuery.forEach(item => {
        let data = {
          staffConfirmID: item.staffConfirmID,
          cashier: item.receiptionist,
          originalPrice: item.originalPrice,
          promotionPrice: item.promotionPrice,
          differencePrice: item.differencePrice,
          finalPrice: item.finalPrice,
        };
        
        // find
        let foundCashier = this.listPaymentWithReceptionist.find(item => item.staffConfirmID == data.staffConfirmID);
        if (typeof foundCashier == 'undefined') {
          this.listPaymentWithReceptionist.push(data);
        } else {
          this.listPaymentWithReceptionist = this.listPaymentWithReceptionist.map(i =>
          {
            if (i.staffConfirmID == data.staffConfirmID) {
              i.finalPrice = i.finalPrice + data.finalPrice;
              i.originalPrice = i.originalPrice + data.originalPrice;
              i.promotionPrice = i.promotionPrice + data.promotionPrice;
              i.differencePrice = i.differencePrice + data.differencePrice;
            }
            return i
          })
        }
      })
    
    },

    reRenderColumns() {
      if (!this.showDetailItems) {
        // update the columns
        this.columns = [
          { field: 'order', title: 'STT', width: '80px'},
          { field: 'dateCreated', title: 'Ngày tạo', filter: 'date', width: '200px'},
          { field: 'dateConfirmed', title: 'Ngày thanh toán',filter: 'date', width: '200px'},
          { field: 'clinicName', title: 'Clinic', width: '200px', columnMenu: 'headColumnMenu' }, 
          { field: 'patientID', title: 'Mã BN', width: '150px' },
          { field: 'patientName', title: 'Tên BN', width: '150px' },
          { field: 'packageService', title: 'Dịch vụ', width: '250px' }, // different here
          { field: 'departmentName', title: 'Khoa', width: '150px', columnMenu: 'headColumnMenu'}, 
          { field: 'paymentMethodDescription', title: 'HT thanh toán', width: '150px', columnMenu: 'headColumnMenu'},
          { field: 'promotionName', title: 'CT Khuyến mãi', width: '150px' },
          { field: 'originalPrice', title: 'Giá gốc', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalOriginalPriceCell },
          { field: 'promotionPrice', title: 'Giá KM', width: '200px', footerCell: this.totalPromotionPriceCell },
          { field: 'afterPromotionPrice', title: 'Giá sau KM', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalAfterPromotionPrice },
          { field: 'differencePrice', title: 'Giá điều chỉnh', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalDifferencePriceCell },
          { field: 'finalPrice', title: 'Thực nhận', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalFinalPriceCell },
          { field: 'receiptionist', title: 'Thu ngân', width: '150px' },
          { field: 'note', title: 'Ghi chú', width: '250px' }
        ]
      } else {
        this.columns = [
          { field: 'order', title: 'STT', width: '80px'},
          { field: 'dateCreated', title: 'Ngày tạo', filter: 'date', width: '200px'},
          { field: 'dateConfirmed', title: 'Ngày thanh toán',filter: 'date', width: '200px'},
          { field: 'clinicName', title: 'Clinic', width: '200px', columnMenu: 'headColumnMenu' }, 
          { field: 'patientID', title: 'Mã BN', width: '150px' },
          { field: 'patientName', title: 'Tên BN', width: '150px' },
          { field: 'axPxName', title: 'Item', width: '200px', columnMenu: 'headColumnMenu'}, // different
          { field: 'serviceName', title: 'Dịch vụ', width: '250px' },                         // different
          { field: 'isIncluded', title: 'Thuộc service', width: '100px', filterable: false },
          { field: 'departmentName', title: 'Khoa', width: '150px', columnMenu: 'headColumnMenu'}, 
          { field: 'paymentMethodDescription', title: 'HT thanh toán', width: '150px', columnMenu: 'headColumnMenu'},
          { field: 'promotionName', title: 'CT Khuyến mãi', width: '150px' },
          { field: 'originalPrice', title: 'Giá gốc', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalOriginalPriceCell },
          { field: 'promotionPrice', title: 'Giá KM', width: '200px', footerCell: this.totalPromotionPriceCell },
          { field: 'afterPromotionPrice', title: 'Giá sau KM', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalAfterPromotionPrice },
          { field: 'differencePrice', title: 'Giá điều chỉnh', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalDifferencePriceCell },
          { field: 'finalPrice', title: 'Thực nhận', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalFinalPriceCell },
          { field: 'receiptionist', title: 'Thu ngân', width: '150px' },
          { field: 'note', title: 'Ghi chú', width: '250px' }
        ]
      }
      // adjust fields of report detail
      this.exportExcel.detailOrder.customFields(this.showDetailItems);
      this.exportExcel.detailOrder.customHeader(this.searching, this.showDetailItems)
    },

    reportDepartment() {

      this.reportDepartmentPrice = []

      let listItems = JSON.parse(JSON.stringify(this.listOrderPayment.items))
      listItems.forEach(itemOrder => {
        let newData = {
          departmentName: itemOrder.departmentName,
          departmentID: itemOrder.departmentID,
          originalPrice: itemOrder.originalPrice,
          promotionPrice: itemOrder.promotionPrice,
          differencePrice: itemOrder.differencePrice,
          finalPrice: itemOrder.finalPrice,
        }
        let findData = this.reportDepartmentPrice.find( i => i.departmentID == newData.departmentID);
        if (typeof findData == 'undefined') {
          this.reportDepartmentPrice.push(newData)
        } else {
          let data = this.reportDepartmentPrice.slice().filter( i => i.departmentID == newData.departmentID);
          data[0].originalPrice = data[0].originalPrice + newData.originalPrice;
          data[0].promotionPrice = data[0].promotionPrice + newData.promotionPrice;
          data[0].differencePrice = data[0].differencePrice + newData.differencePrice;
          data[0].finalPrice = data[0].finalPrice + newData.finalPrice;
        }
      })
    },

    savePDF() {
       this.$refs.PdfExport.save();
    }
  }

}
</script>

<style>

</style>