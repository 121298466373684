import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Hr);

export default {
  searchClinicRooms(keySearch = "", page = 1, limit = 10) {
    var query = {
      keySearch,
    }
    var headers = {
      page,
      limit,
    }
    return httpClient.get("/Room/Search", query, headers);
  },
  searchBrandClinics(page = 1, limit = 10) {
    var query = {
      clinicGroupID: "",
      statusID: 1,
      keySearch: "",
    }
    var headers = {
      page,
      limit,
    }
    return httpClient.get("/Brand/Clinic/Search", query, headers);
  },
  getBrandClinicByID(clinicID) {
    var query = {
      clinicID
    }
    return httpClient.get("/Brand/Clinic/Get/{clinicID}", query);
  }
}