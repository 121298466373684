import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'

var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/DepartmentClinic'

export default {
  searchDepartmentByClinic: function (keySearch, clinicID = null, page = 1, limit = 10) {
    return httpClient.get(`${prefix}/Searching`, {
      keySearch, 
      clinicID,
    }, { page, limit: AppConstant.limit || limit})
  }
}