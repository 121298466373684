<template>
  <div class="d-block w-100">
    <Grid
      :sortable="sortable"
      :filterable="filterable"
      :groupable="groupable"
      :reorderable="reorderable"
      :resizable="resizable"
      :data-items="dataResult"
      :column-menu="columnMenu"
      :skip="skip" 
      :take="take" 
      :sort="sort" 
      :group="group" 
      :filter="filter"            
      :columns="listColumn"
      :expand-field="'expanded'"
      @datastatechange="dataStateChange"
      @columnreorder="columnReorder"
      :cell-render="customField ? cellTemplate : null"
      @expandchange="expandChange"
      >
      <!-- it is good when choosing a group = true -->
        <template v-slot:myTemplate="{props}">
          <component :is="customCellComponent"
            :field="props.field"
            :expanded="props.expanded"
            :row-type="props.rowType"
            :level="props.level"
            :column-index="props.columnIndex"
            :columns-count="props.columnsCount"
            :data-item="props.dataItem"
            :class-name="props.className"
            @click="handleExpandedGroup(props.dataItem)" 
          ></component>
        </template>

        <template v-slot:headColumnMenu="{props}">
          <grid-head-column-menu
            :column="props.column"
            :filterable="props.filterable"
            :filter="props.filter"
            :sortable="props.sortable"
            :sort="props.sort"
            :columns="listColumn"
            :dataResult="dataItems"
            @columnssubmit="onColumnsSubmit"
            @filter-checkbox="handleFilterCheckBox"
            @filter-menu-filter="handleFilterMenu"
            @change-state-lock-column="handleChangeStateColumn"
          />
        </template>
      </Grid>
  </div>
</template>

<script>
import _ from 'lodash'
import { process } from '@progress/kendo-data-query';
import { Grid } from '@progress/kendo-vue-grid';
import GridHeadColumnMenu from './GridHeadColumnMenu'

export default {
  name: "KendoGridDataComponent",
  components: {
    Grid,
    'grid-head-column-menu': GridHeadColumnMenu
  },
  props: {
    columns: {
      type: Array,
      default: function() { return [] }
    },
    config: {
      type: Object,
      default: function() { return null }
    },
    dataItems: {
      type: Array,
      default: function() { return [] }
    },

  },
  watch: {
    columns() {
      this.listColumn = this.columns
    },
    config() {
      this.parseConfig()
      this.getData()
    },
    dataItems() {
      this.getData()
    }
  },  
  mounted() {
    this.listColumn = this.columns
    this.parseConfig()
    this.getData()
  },
  data() { 
    return {
      listColumn: [],
      dataResult: [],

      sortable: false,
      filterable: false,
      groupable: false,
      reorderable: false,
      resizable: false,
      columnMenu: false, // if column  menu == true ==> should not use the columnMenu props in list columns

      sort: [],
      group: [],
      filter: null,

      skip: 0, 
      take: 10,
      
      customField: false,
      cellTemplate: 'myTemplate',
      customCellComponent: null,
    }
  },
  methods: {
    upperFirst(string) {
      return _.upperFirst(string)
    },
    parseConfig() {
      if (this.config != null) {
        this.sortable = this.config.sortable ?? false;
        this.filterable = this.config.filterable ?? false;
        this.groupable = this.config.groupable ?? false;
        this.reorderable = this.config.reorderable ?? false;
        this.resizable = this.config.resizable ?? false;
        this.columnMenu = this.config.columnMenu ?? false;

        this.skip = this.config.skip ?? 0;
        this.take = this.config.take ?? 10;

        this.sort = this.config.sort ?? [];
        this.group = this.config.group ?? [];
        this.filter = this.config.filter ?? null;

        this.customField = this.config.custom ?? false
        this.customCellComponent = this.config.cellComponent ?? null
      }
    },
    getData() {
      this.dataResult = process(this.dataItems, {
        take: this.take,
        skip: this.skip,
        group: this.group,
        filter: this.filter
      })
    },
    createAppState(dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      if (dataState.group) {
          dataState.group.map(group => group.aggregates = this.aggregates);
      }
      this.group = dataState.group;
      this.filter = dataState.filter;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
 
      this.createAppState(event.data);
      this.dataResult = process(this.dataItems, {
          skip: this.skip,
          take: this.take,
          sort: this.sort,
          filter: this.filter,
          group: this.group
      });
    },
    columnReorder(options) {
      this.listColumn = options.columns;
    },
    clickHandler(dataItem){
      dataItem.expanded = dataItem.expanded === undefined ? false : !dataItem.expanded;
    },
    expandChange(event) {
      event.dataItem[event.target.$props.expandField] = event.value;
      console.log(event)
    },
    onColumnsSubmit (columnsState) {
      this.listColumn = columnsState;
    },
    // handle filter from customHeader
    handleFilterCheckBox(eventFilter) {
      this.filter = eventFilter;
      this.getData();
    },
    handleFilterMenu(eventFilter) {
      this.filter = eventFilter;
      this.getData()
    },
    handleChangeStateColumn(column) {
     this.listColumn = this.listColumn.map(c => {
       if (c.field == column.field) {
        c.locked = !c.locked
       }
       return c;
     })
    },
    handleExpandedGroup(dataItem) {
      dataItem.expanded = dataItem.expanded === undefined ? false : !dataItem.expanded;
    },
    getDataResult() {
      return this.dataResult;
    }
  }
}
</script>

<style>

</style>