<template>
  <div class="d-flex flex-column p-1">
    <div class="d-block w-100">
      <pager-configuration
        :type="type"
        :buttonCount="buttonCount"
        :info="info"
        :pageSizes="pageSizes"
        @typeChange="(inputType)=>onTypeChange(inputType)"
        @buttonCountChange="(buttonsNumber)=>onButtonCountChange(buttonsNumber)"
        @showInfoChange="(showInfo)=>onShowInfoChange(showInfo)"
        @pageSizeChange="(pageChanged)=>onPageSizeChange(pageChanged)"
        @previousNextChange="(prevNextChanged)=>onPreviousNextChange(prevNextChanged)"
        :horizontal="true"
      />
    </div>

    <!-- SLOT Content here -->
    <div class="d-block w-100 mt-2 mb-2">
      <slot></slot>
    </div>
    

    <div class="d-block w-100">
      <pager
        :skip="skip" 
        :take="take" 
        :total="total" 
        :button-count="buttonCount" 
        :info="info" 
        :type="type" 
        :page-sizes="pageSizes ? pageSizeDefs : undefined"
        :previous-next="previousNext"
        @pagechange="handlePageChange"
        >
      </pager>
    </div>
  </div>
</template>

<script>
import { Pager } from '@progress/kendo-vue-data-tools';
import PaperConfiguration from './PagerConfiguration' 

const initialPagesState = {
  skip: 0,
  take: 10,
  buttonCount: 5,
  type: 'numeric',
  info: true, 
  pageSizes: true, 
  previousNext: true
}

export default {
  name: 'KendoPaperComponent',
  components: {
    "pager": Pager,
    "pager-configuration": PaperConfiguration,
  },
  props: {
    config: {
      type: Object,
      default: () => null
    },
    total: {
      type: Number,
      default: 50
    }
  },
 
  data: () => {
    return {
      skip: initialPagesState.skip,
      take: initialPagesState.take,
      type: initialPagesState.type,
      buttonCount: initialPagesState.buttonCount,
      info: initialPagesState.info,
      pageSizes: initialPagesState.pageSizes,
      previousNext: initialPagesState.previousNext,
      pageSizeDefs:[],
    }
  },

  created() {
    if (this.config != null) {
      this.skip = this.config.skip ?? initialPagesState.skip;
      this.take = this.config.take ?? initialPagesState.take; 
      this.type = this.config.type ?? initialPagesState.type;
      this.buttonCount = this.config.buttonCount ?? initialPagesState.buttonCount;
      this.info = this.config.info ?? initialPagesState.info;
      this.pageSizes = this.config.pageSize ?? initialPagesState.pageSizes;
      this.previousNext = this.config.previousNext ?? initialPagesState.previousNext;
      this.pageSizeDefs = this.config.pageSizeDefs ?? [5,10,20,50,100,200,500];
    }
  },

  methods: {
    onTypeChange(type) {
      this.type = type;
    },
    onButtonCountChange(numberButton) {
      this.buttonCount = parseInt(numberButton);
    },
    onShowInfoChange(showOrOff) {
      this.info = showOrOff;
    },
    onPageSizeChange(showPageSize) {
      this.pageSizes = showPageSize;
    },
    onPreviousNextChange(showPrevious) {
      this.previousNext = showPrevious;
    },
    handlePageChange: function(event){
      console.log(event)
      this.skip=event.skip;
      this.take=event.take;

      this.emitConfig()
    },

    emitConfig() {
      this.$emit('input',{
        skip: this.skip,
        take: this.take
      });
    }
  }
}
</script>

<style>

</style>