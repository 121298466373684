<template>
  <v-card elevation="0" outlined>
    <v-card-title class="d-flex justify-space-between">
      <span class="font-weight-bold">Report money</span>
      <download-excel
        :data="reportPaymentPrice"
        :fields="exportExcel.fields"
        type="xls"
        :name="exportExcel.name"
        :header="exportExcel.header"
        :worksheet="exportExcel.worksheet"
        :footer="exportExcel.footer"
        >
        <v-icon size="30" color="#007c00" style="cursor: pointer">mdi-microsoft-excel</v-icon>
      </download-excel>
    </v-card-title>
    <v-card-text>
      <template v-if="reportPaymentPrice != null">
        <template v-if="reportPaymentPrice.length">
          <div class="d-flex align-center justify-space-between mt-2" v-for="(itemPrice, key) in reportPaymentPrice" :key="key">
            <span class="text-subtitle-1 font-weight-bold">{{ itemPrice.text }}</span>
            <span class="red--text font-weight-bold text-subtitle-1">{{ currencyFormat(itemPrice.cash, 'end', ' VND') }}</span>
          </div>
        </template>
        <template v-else>
          <div class="d-flex align-center justify-center p-5">
            <span class="grey--text font-weight-bold">Data is empty!</span>
          </div>
        </template>
      </template>
      <template v-else>
        <v-skeleton-loader
          type="text, text, text, text"
          ></v-skeleton-loader>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import JsonExcel from "vue-json-excel";
import { currencyFormat } from '@/plugins/helper'
import moment from 'moment'
export default {
  name: 'ReportMoneyComponent',
  components: {
    'download-excel': JsonExcel
  },  
  props: {
    reportPaymentPrice: {
      type: Array,
      default: function () { return null }
    },
    searching: Object
  },
  watch: {
    searching() {
      this.exportExcel.customHeader(this.searching)
    }
  },
  data: function() { 
    return {
      exportExcel: {
        fields: {
          'Mô tả': 'text',
          'Thành tiền': 'cash'
        },
        name: 'ReportCash.xls',
        header: ['<h5>Report Department Cash</h5>'],
        footer: [],
        customHeader: function(searching) {
          this.header = ['<h5>REPORT DEPARTMENT CASH</h5>'];
          let {startTime, endTime, clinicName, departmentName}  = searching;
          let timing = `<b style="color: red">${moment(startTime).format('DD/MM/YYYY')}</b>  -  <b style="color: red">${moment(endTime).format('DD/MM/YYYY')}</b>`;
          this.header.push(timing);
          let clinic = clinicName == '' ? 'Clinic: <b style="color:red">ALL</b>' : `Clinic: <b style="color:red">${clinicName}</b>`;
          this.header.push(clinic);
          let department = departmentName == '' ? 'Department: <b style="color:red">ALL</b>': `Department: <b style="color:red">${departmentName}</b>`
          this.header.push(department)
        }
      }
    }
  },
  methods: {
    currencyFormat
  }
}
</script>

<style>

</style>