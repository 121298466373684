<template>
  <v-sheet class="mp-2">
    <!-- Configuration type and how many button for collapse -->
    <v-row>
        <!-- Paper type -->
        <v-col cols="2">    
          <div class="d-flex flex-column">
            <span class="font-weight-bold">Paper types:</span>
            <div class="d-flex mt-1">
              <v-radio-group row dense class="ma-1"
                :value="type"
                @change="changeType"
                > 
                <v-radio
                  color="red"
                  label="Numeric"
                  value="numeric"
                ></v-radio>

                <v-radio
                  color="red"
                  label="Input"
                  value="input"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-col>
        <!-- Button for the collapse -->
        <v-col cols="2">
          <div class="d-flex flex-column">
            <span class="font-weight-bold">Max. number of buttons:</span>
            <div class="d-block mt-1" style="width: 50%">
              <v-text-field
                class="ma-0 pa-0"
                @change="changeNumberButtonToCollapse"
                :value="buttonCount"
                type="number"
                required
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <!-- Turn on off info column -->  
        <v-col cols="5">
          <div class="d-flex align-center w-100" :class="{ 'flex-column': !horizontal, 'flex-row': horizontal }">
            <!-- Show Info -->
            <v-checkbox 
              v-model="showInfo"
              class="ma-1"
              label="Show info"
              color="red"
              dense
            />
            <!-- Show Page sizes -->
            <v-checkbox 
              v-model="showPageSizes"
              class="ma-1"
              label="Show page sizes"
              color="red"
              dense
            />
            <!-- Previous Next -->
            <v-checkbox 
              v-model="previousNext"
              class="ma-1"
              label="Show previous / next buttons"
              color="red"
              dense
            />
          </div>
        </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'PaperConfigurationComponent',
 
  props: {
    type: String,
    buttonCount: Number,
    info: Boolean,
    pageSizes: Boolean,
    horizontal: Boolean,
    useExport: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.infoCheck = this.info
    this.showPageSizes = this.pageSizes
  },
  watch: {
    showInfo() {
      this.changeInfo()
    },
    showPageSizes() {
      this.changePageSizes()
    },
    previousNext() {
      this.changePrevious()
    }
  },
  data: () => {
    return {
      showInfo: true,
      showPageSizes: true,
      previousNext: true
    }
  },
  methods: {
    changeType(value) {
      this.$emit('typeChange', value);
    },
    changeNumberButtonToCollapse(value) {
      this.$emit('buttonCountChange', value);
    },
    changeInfo() {
      this.$emit('showInfoChange', this.showInfo);
    },
    changePageSizes() {
      this.$emit('pageSizeChange', this.showPageSizes);
    },
    changePrevious() {
      this.$emit('previousNextChange', this.previousNext)
    }

  }
}
</script>

<style scoped>
.item__export {
  cursor: pointer;
}
</style>